<template>
  <!-- Renders only if the product is visible to user -->
  <div
    class="product__wrapper"
    :class="{
      'product__wrapper--expanded': expand,
      'product__wrapper--closed': !expand,
    }"
  >
    <div v-if="expand" itemscope>

      <!---------- Product detail page ------------>

      <div v-if="expand">
        <div v-if="!is_marketplace_environment" class="product__top-bar">
          <div @click="last_visited_page ? $router.push(last_visited_page) : $router.back()" itemprop="back-link" class="product__close">
            <arrow /><span>{{ translations.back_button }}</span>
          </div>
          <div v-if="product_data.id" @click="add_to_liked" class="product__like">
            <heart_minus v-if="!is_product_liked"/>
            <heart_plus v-else />
          </div>
        </div>

        <section v-if="(loading && (still_fetching_products || is_marketplace_environment)) || product_data.id">
          <div v-if="loading" class="element-loading product__gallery"/>
          <Gallery
            v-else
            :images="product_data.images || []"
            :product_name="title"
          />
          <div class="product__right-panel">
            <div v-if="loading" class="product__right-panel-loading">
              <div class="element-loading" />
              <div class="element-loading" />
              <span class="element-loading" />
              <span class="element-loading" />
              <div class="element-loading" />
              <p class="element-loading" />
              <p class="element-loading" />
            </div>
            <Checkout
              v-else
              :product_data="product_data"
              class="right-panel"
              :marketplace_selected_language="selected_marketplace_language"
            />
            <div
              v-if="description && !loading"
              class="product__description"
              v-html="description"
              itemprop="description"
            >
              {{ description }}
            </div>
          </div>
        </section>
        <product_seo_description
          v-if="!loading"
          :product="product_data"
          :currency="is_marketplace_environment ? marketplace_currency : translations.currency_code"
          :selected_marketplace_language="selected_marketplace_language"
        />
        <!-- When product not found -->
        <div v-if="!loading && !(still_fetching_products || is_marketplace_environment) && !product_data.id" class="entity-missing">
          <missing_product />
          <h3>{{ translations.products_missing || "The product you are looking for has not been found." }}</h3>
        </div>
        <!-- TODO Add customer review configuration -->
        <!-- <customer_reviews :product_id="product_data.id"/> -->
      </div>
    </div>

    <!---------- Product preview detail ------------>
    
    <div v-else>
      <div v-if="loading">
        <div class="product__image"><div class="element-loading" /></div>
        <h2 class="product__title element-loading" />
        <p class="product__price element-loading"/>
      </div>
      <div v-if="!loading" @click="add_to_liked" class="product__like">
        <heart_minus v-if="!is_product_liked"/>
        <heart_plus v-else/>
      </div>
      <div v-if="!loading">
        <router-link
          :to="product_url"
          class="link"
          :aria-label="`Checkout item ${title}`"
          @click.native="update_viewed_product_data(product_data)"
          itemprop="category/subcategory/item"
        >
          <div 
            v-if="!has_product_stock"
            class="product__out-of-stock"
          >
            {{ translations.out_of_stock }}
          </div>
          <div data-cy="product-image" class="product__image">
            <v-lazy-image
              :src="choose_img_to_show_according_to_viewport()"
              :src-placeholder="choose_placeholder_img_according_to_viewport()"
              :alt="`${title} product image`"
              itemprop="image"
            />
            <sale_ribon
              :price="price_including_tax_percentage"
              :compare_at_price="compare_at_price_including_tax_percentage"
            />
          </div>
          <div
            v-if="get_product_variants.length > 0"
            class="product__extra-category__wrapper"
          >
            <div
              v-for="extra_category_value in get_product_variants"
              :key="`item-category-${extra_category_value}`"
              class="product__extra-category"
              :style="{
                backgroundColor: is_value_a_color(extra_category_value) ?
                  extra_category_value : project_styles.colors.background_heavy
              }"
              :class="{
                'product__extra-category--not-color': !is_value_a_color(extra_category_value),
              }"
            >
              {{ is_value_a_color(extra_category_value) ? "" : extra_category_value }}
            </div>
          </div>
        </router-link>
        <router-link
          :to="product_url"
          class="link"
          :aria-label="`Checkout item ${title}`"
          @click.native="update_viewed_product_data(product_data)"
          itemprop="category/subcateogry/item"
        >
          <h2 class="product__title">
            <span data-cy="product-title" itemprop="name">{{ title }}</span>
          </h2>
        </router-link>

        <!------------------  Price section  ------------------>

        <p
          class="product__price product__price--sale"
          v-if="first_variant_percentage_price_difference > 0"
        >
          <span>
            <span v-if="is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
              {{ price_including_tax_percentage }}
            <span v-if="!is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
          </span>
          <span> | </span>
          <span>
            <span v-if="is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
              {{ compare_at_price_including_tax_percentage }}
            <span v-if="!is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
          </span>
        </p>
        <p
          data-cy="product-price"
          class="product__price"
          v-else
        >
          <span v-if="is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
            {{ price_including_tax_percentage }}
          <span v-if="!is_currency_symbol(translations.currency_symbol)">{{ translations.currency_symbol }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"
import html_enity_encoder from "urlencode"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import replace_spaces_for_dashes from "../../../Shared/methods/replace_spaces_for_dashes"
import calculate_percentage from "../../methods/calculate_percentage"
import is_value_a_color from "../../../Shared/methods/is_value_a_color"
import { image_dimension_names, user_viewed_products } from "../../../../data/other_constants"
import get_description from "../../../Shared/methods/get_description_from_product_description"
import get_message from "../../../Shared/methods/get_message_or_types_from_product_description"
import { CLIENT_STORE } from "../../constants/other"
import { content_pages, SHARED_STORE } from "../../../Shared/constants/other"
import create_correct_product_url from "../../methods/create_correct_product_url"
import heart_plus from "../../../Shared/components/icon_components/heart-plus-icon"
import heart_minus from "../../../Shared/components/icon_components/heart-minus-icon"
import arrow from "../../../Shared/components/icon_components/arrow"
import sale_ribon from "../../../Shared/components/utils/product_sale_ribon"
import { MARKETPLACE } from "../../../Marketplace/constants/other"
import { update_product_views } from "../../../Shared/methods/ajax/endpoints"
import is_currency_symbol from "../../../Shared/methods/is_currency_symbol"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { ARE_ANY_PRODUCTS_BEING_FETCHED, SET_VIEWED_PRODUCTS, TOGGLE_LIKED_PRODUCT_STATE, UPDATE_VIEWED_PRODUCT } from "../../stores/Client/constants"
import { event_hub } from "../../../../main"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import round_to_two_decimals from "../../methods/round_to_two_decimals"

const Gallery = () => import("../../../Shared/components/utils/gallery")
const Checkout = () => import("../../../Shared/components/utils/checkout")
const product_seo_description = () => import("../../../Shared/components/utils/product_seo_description")
const customer_reviews = () => import("../../../Shared/components/utils/customer-reviews/customer_reviews")
const missing_product =  () => import("../../../Shared/components/icon_components/missing-product.vue")

export default {
  props: {
    product_data: {
      type: Object,
      required: true
    },
    expand: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    selected_marketplace_language: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    Gallery,
    Checkout,
    product_seo_description,
    customer_reviews,
    heart_plus,
    heart_minus,
    sale_ribon,
    arrow,
    missing_product,
    VLazyImage
  },
  data() {
    return {
      get_parent_height: 0,
      scrolled_depth: 0,
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
    ]),
    ...mapState(CLIENT_STORE, [
      "translations",
      "viewed_products",
      "liked_products",
      "user_is_using_fast_connection",
      "viewed_product_id",
      "project_styles",
      "collections",
      "selected_language"
    ]),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState([
      "is_marketplace_environment",
      "last_visited_page"
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    compare_at_price_including_tax_percentage() {
      let temp_price = this.first_variant.compare_at_price

      if (this.translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
    price_including_tax_percentage() {
      let temp_price = this.first_variant.price

      if (this.translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
    language() {
      return this.selected_marketplace_language || this.selected_language
    },
    title() {
      return get_correct_translation_value(this.product_data, "title", [this.language])
    },
    body_html() {
      return get_correct_translation_value(this.product_data, "body_html", [this.language])
    },
    /*
     *
     */
    has_product_stock() {
      // Check if product variants is an array or object
      return this.product_data.variants.filter(({ inventory_quantity }) => inventory_quantity > 0).length
    },
    /*
     *
     */
    website_name() {
      return this.loading ? " " : this.translations.website_name.replace(/\s/g, "_")
    },
    /*
     *
     */
    is_product_liked() {
      return this.liked_products.some(product_id => product_id === this.product_data.id)
    },
    /*
    * 
    */
    product_url() {
      let { category = "", subcategory = "" } = this.$route.params
      category = html_enity_encoder(category)
      subcategory = html_enity_encoder(subcategory)

      if (category === content_pages.LIKED) return create_correct_product_url({
        id: this.product_data.id,
        collections: this.product_data.collections
      })

      return this.loading ?
        " " :
        `/Products${category ? `/${category}` : ""}${subcategory ? `/${subcategory}` : ""}/${this.product_data.id}`
    },
    /*
    * 
    */
    get_product_variants() {
      const array_or_categories = Object.values(this.product_data.variants).map(({ main_value }) => main_value)

      // Check if product has categories
      return array_or_categories[0] ? array_or_categories : []
    },
    /*
     * 
     */
    description() {
      return this.loading ? " " : get_description(this.body_html)
    },
    /*
     * 
     */
    message() {
      return this.loading ? " " : get_message(this.body_html)
    },
    /*
     * 
     */
    first_variant() {
      return {
        ...this.product_data.variants[0],
        price: get_correct_translation_value(this.product_data.variants[0], "price", [this.language], false),
        compare_at_price: get_correct_translation_value(this.product_data.variants[0], "compare_at_price", [this.language], false)
      }
    },
    /*
     * 
     */
    first_variant_percentage_price_difference() {
      return calculate_percentage(this.first_variant.compare_at_price, this.first_variant.price)
    }
  },
  watch: {
    expand(val) {
      if (val) {
        this.update_view()
        event_hub.$emit(
          "expand_product",
          this.$route.params.subcategory || this.$route.params.category || this.$route.params.product,
          this.product_data.type // TODO add product types to enable similar products
        );
      }
    },
    product_data(val) {
      this.should_product_be_assigned_to_viewed()

      if (val) this.product_initial_functions()
    }
  },
  created() {
    this.should_product_be_assigned_to_viewed()
  },
  mounted() {
    if (this.expand) event_hub.$emit(
      "expand_product",
      this.$route.params.subcategory || this.$route.params.category || this.$route.params.product,
      this.product_data.type // TODO add product types to enable similar products
    );
    if (this.product_data)this.product_initial_functions()

    // FB event
    if (window.fbq) fbq("track", "ViewContent", {
      value: this.product_data.price,
      currency: this.translations.currency_code, 
      content_ids: this.product_data.id,
      content_type: "product",
    });
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      set_viewed_products: SET_VIEWED_PRODUCTS,
      update_viewed_product_data: UPDATE_VIEWED_PRODUCT,
      toggle_liked_product_state: TOGGLE_LIKED_PRODUCT_STATE
    }),
    replace_spaces_for_dashes,
    calculate_percentage,
    is_value_a_color,
    is_currency_symbol,
    product_initial_functions() {
      this.update_viewed_product_data(this.product_data)
      this.update_view()
    },
    update_view() {
      if (this.product_data.id) update_product_views({
        product_route: this.is_marketplace_environment ?
          `${this.product_data.categories.join("_")}_${this.product_data.id}` : this.product_data.id,
        product_project_id: this.product_data.project_id,
        product_id: this.product_data.id
      })
    },
    /*
     *
     */
    choose_img_to_show_according_to_viewport() {
      return get_correct_first_image(
        this.product_data,
        this.user_is_using_fast_connection ? image_dimension_names.tablet : image_dimension_names.mobile
      )
    },
    /*
     *
     */
    choose_placeholder_img_according_to_viewport() {
      return get_correct_first_image(this.product_data)
    },
    /*
     *
     */
    should_product_be_assigned_to_viewed() {
      if (this.expand) this.assign_product_to_viewed_products()
    },
    /*
     *
     */
    check_if_product_in_viewed_products() {
      return this.viewed_products.some(product_id => product_id === this.product_data.id)
    },
    /*
     *
     */
    assign_product_to_viewed_products() {
      if (!this.check_if_product_in_viewed_products() && this.product_data.id) {
        const updated_viewed_products = [
          ...this.viewed_products,
          this.product_data.id,
        ]

        window.localStorage.setItem(
          user_viewed_products,
          JSON.stringify(updated_viewed_products)
        )
        this.set_viewed_products(updated_viewed_products)
      }
    },
    /*
     *  Adds product to liked
     */
    add_to_liked() {
      // FB event
      if (window.fbq) fbq("track", "AddToWishlist", {
        value: this.product_data.price,
        currency: this.translations.currency_code, 
        content_ids: this.product_data.id,
        content_type: "product",
      });

      this.toggle_liked_product_state([
        { ...this.product_data, route: this.product_url },
        !this.is_product_liked
      ])
    },
    /*
     *  Expands the product to show detail page
     */
    expansion() {
      if (this.expand) {
        event_hub.$emit("clear_size");
      } else {
        this.scrolled_depth = document.querySelector("html").scrollTop;
      }

      // Scroll to previously scrolled
      setTimeout(
        () => document.querySelector("html").scrollTop = !this.expand ? 0 : this.scrolled_depth,
        100
      );
    }
  }
};
</script>

<style lang="scss" scoped>
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/rise_up_effect.scss";
  @use "../../../../styles/element_loading.scss";
  @use "../../../../styles/missing_entity.scss";

  .product {
    &__out-of-stock {
      position: absolute;
      width: 100%;
      top: calc(50% - 10px);
      left: 0;
      z-index: 2;
      text-align: center;
      line-height: 35px;
      font-size: 17px;
      background-color: $shadow-color;
      color: $pure-white;
      transition: $default-transition;

      @media (max-width: $tablet) {
        font-size: 14px;
        line-height: 25px;
      }
    }

    &__gallery {
      display: inline-block;
      width: 50%;
      height: 400px;

      @media (max-width: $tablet) {
        height: 300px;
      }
      @media (max-width: $tablet--small) {
        width: 100%;
      }
    }

    &__wrapper {
      position: relative;
      display: inline-block;
      width: calc(25% - 10px);
      margin: 5px;
      cursor: pointer;
      vertical-align: top;
      box-sizing: border-box;
      transition: $default-transition;

      &:hover {
        .product__like, .product__image, .product__out-of-stock {
          @extend .rise-up-effect;
        }
      }

      &--expanded {
        display: block;
        width: 100% !important;
        padding: 0 20px 20px;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        cursor: initial;
        overflow: unset;

        @media (max-width: $tablet) {
          padding: 0 0 20px;
        }

        &:hover {
          .product__like, .product__image {
            transform: translateY(0) !important;
            box-shadow: none;
          }
        }

        .product {
          &__wrapper {
            @media (max-width: $tablet--small) {
              padding-top: 30px; 
            }
          }

          &__title {
            padding: 5px 0 0;
          }

          &__like {
            top: 20px;
            right: 20px;

            svg {
              fill: $pure-black;
            }

            @media (max-width: $tablet) {
              right: 0;
            }
          }
        }
      }

      @media (max-width: $maximum-width) {
        width: calc(33.3% - 10px);
      }

      @media (max-width: $tablet--small) {
        width: calc(50% - 10px);
      }
    }

    &__right-panel {
      width: calc(50% - 5px);
      display: inline-block;
      vertical-align: top;
      padding-left: 20px;
      box-sizing: border-box;

      &-loading {
        div {
          height: 30px;
          margin: 10px 0;

          &:first-child {
            margin-top: 0;
          }
        }

        span {
          display: block;
          height: 40px;
          width: 50%;
          margin-top: 20px;
        }

        p {
          height: 200px;
          margin-top: 20px;
        }
      }

      @media (max-width: $tablet--small) {
        display: block;
        width: 100%;
        padding: 0 10px;
        margin: 20px 0 0;
      }
    }

    &__sale-ribbon {
      position: absolute;
      top: 13px;
      left: -65px;
      width: 200px;
      padding: 5px 0;
      font-size: 13px;
      background: $alert-color;
      color: $pure-white;
      transform: rotate(-45deg);
      text-align: center;
    }

    &__image {
      position: relative;
      border: 1px solid var(--light_grey);
      border-radius: 6px;
      padding: 20px;
      transition: $default-transition;
      background: $pure_white;
      overflow: hidden;

      img, .element-loading {
        display: block;
        margin: auto;
        object-fit: contain;
        height: 160px;
        width: 160px;
      }

      @media (max-width: $tablet) {
        img, .element-loading {
          width: 100%;
          object-fit: scale-down;
        }
      }
    }
    
    &__extra-category {
      display: inline-block;
      width: 20px;
      height: 100%;
      margin: 0 5px 5px 0;
      box-shadow: 0px 0px 9px -2px $shadow-color;

      &--not-color {
        width: auto;
        height: 100%;
        padding: 0 5px;
        line-height: 20px;
        font-size: 10px;
        color: $pure-white;
      }

      &__wrapper {
        position: absolute;
        width: 100%;
        height: 30px;
        padding: 5px 10px;
        transform: translateY(-30px);
        box-sizing: border-box;
        overflow: hidden;
      }
    }

    &__title {
      padding: 10px 0 5px;
      margin: 0;
      font-weight: bold;
      background-color: var(--body_color);
      color: var(--font_heavy);
      font-size: 16px;

      &.element-loading {
        height: 20px;
        margin: 5px 0 10px;
        width: 50%;
      }

      span {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px 0;
        box-sizing: border-box;
      }

      @media (max-width: $tablet) {
        font-size: 14px;
        padding: 5px 0 0;
      }
    }

    &__price {
      padding: 5px 10px 10px;
      margin: 0;
      font-size: 15px;
      background-color: var(--body_color);
      color: var(--font_heavy);

      &.element-loading {
        width: 30%;
      }

      &--sale {
        padding: 5px 10px 6px;

        & > span {
          display: inline-block;
          vertical-align: middle;

          &:first-child {
            color: $red-color;
            font-size: 18px;

            @media (max-width: $tablet) {
              font-size: 16px;
            }
          }

          &:nth-child(2) {
            margin: 0 5px;
            font-size: 15px;

            @media (max-width: $tablet) {
              font-size: 12px;
            }
          }

          &:last-child {
            padding-top: 2px;
            font-size: 11px;
            text-decoration: line-through;
          }
        }

        @media (max-width: $tablet) {
          padding: 5px 10px 3px !important;
        }
      }

      @media (max-width: $tablet) {
        font-size: 14px;
        padding: 5px 10px;
      }
    }

    &__top-bar {
      padding-top: 20px;
      margin: 0 0 40px;

      div {
        display: inline-block;
        height: 20px;
        color: var(--font_heavy);
        cursor: pointer;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          top: 20px;
          right: 20px;
          fill: var(--font_heavy) !important;

          @media (max-width: $tablet) {
            right: 0;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__close {
      svg {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }

    &__description {
      padding: 10px;
      margin: 20px 0 0;
      color: var(--font_light);
      background-color: var(--background_heavy);
      text-align: left !important;
      font-weight: normal;
      font-size: 15px;
      border-radius: 6px;
    }

    &__like {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      z-index: 2;
      transition: $default-transition;
      box-shadow: none !important;

      svg {
        fill: $pure-black;
      }
    }
  }
</style>

<style lang="scss">
  .product__description {
    h1, h2, h3, h4, p {
      color: var(--font_light) !important;
      text-align: left;
      margin: 0 0 20px;
    }

    h3 {
      font-size: 20px !important;
      padding: 0;
    }

    p, ul, ol {
      margin: 0 0 10px;
    }

    h5 {
      font-size: 13px;
    }

    h5, h6 {
      color: var(--font_light);
      text-align: left;
      margin: 0 0 10px;
    }
  }
</style>
